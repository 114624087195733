import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonDropdown,
  Card,
  CardHeader,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as $ from "lodash";
import { DATE_FORMATS, MARKET_PLACES, STATUS } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/repricer/actions";
import RepricerListingStats from "./Components/RepricerListingStats";
import CustomPagination from "../../components/Common/CustomPagination";
import {
  amountText,
  daysLeftInTrial,
  formatNumber,
  getMenuPlacement,
  getProductURL,
  getValidStores,
  isAdmin,
  isJSON,
  isProfitCalculationAllowed,
  isUserHaveFullAccess,
  removeURLQueryParams,
  RenderIf,
  toF,
  toPascalCase,
} from "../../utils/common";
import CheckBox from "../../components/Common/CheckBox";
import ReactTable from "../../components/Common/Table";
import StatusBadge from "../../components/Common/StatusBadge";
import moment from "moment";
import Select from "react-select";
import HeaderBanner from "../../components/Common/headerBanner";
import EditableField from "./Components/EditableField";
import StoreSelector from "../../components/Common/StoreSelector";
import CopyToClipBoard from "../../components/Common/CopyToClipBoard";
import queryString from "query-string";
import CustomTooltip from "../../components/Common/CustomTooltip";
import SimpleSelect from "../../components/Common/SimpleSelect";
import ConfirmationDialog from "./Components/ConfirmationDialog";
import PillBtn from "../../components/Common/PillBtn";
import Preloader from "../../components/Common/Preloader";
import { setPreloader } from "../../store/actions";
import AssignStrategyConfirmationDialog from "./Components/AssignStrategyConfirmationDialog";
import { getAccount } from "./common";
import ListingFilters from "./Components/ListingFilters";
import FilterChipsBar from "./Components/FilterChipsBar";
import { DATE_FILTERS, INVENTORY_AGE_FILTERS, WALMART_INVENTORY_AGE_FILTERS } from "./Components/common";
import FiltersDrawer from "./Components/FiltersDrawer";
import DimensionModal from "../../components/Common/DimensionModel";
import EditableFieldWithDropdown from "./Components/EditableFieldWithDropdown";

const TooltipLayout = ({ title, id, targetKey, children = <></> }) => (
  <CustomTooltip
    placement="top"
    style={{ maxWidth: "fit-content" }}
    content={
      <>
        <RenderIf isTrue={title}>
          <div className="d-flex font-size-16 mb-2">{title}</div>
        </RenderIf>
        <div className="mb-2" style={{ minWidth: "max-content" }}>
          {children}
        </div>
      </>
    }
    target={`${targetKey}-tooltip-${id}`}
    modifiers={{ preventOverflow: { enabled: false }, fade: { enabled: false } }}
  />
);

const TooltipData = ({ data }) =>
  data.map((item, index) => (
    <React.Fragment key={"__" + index}>
      <RenderIf isTrue={!$.isNil(item.value)}>
        <div className={`d-flex font-size-${item.isHeading ? "16" : "12"} justify-content-between`}>
          <div className="mr-1">{item.label}:</div>
          <div className="ml-1">{item.isPercentage ? `${item.value}%` : item.isSimpleNum ? item.value : `${amountText(item.value)}`}</div>
        </div>
        <RenderIf isTrue={item.divider}>
          <hr className="my-2" />
        </RenderIf>
      </RenderIf>
    </React.Fragment>
  ));

const tooltipLabels = (obj, isMarketplaceFee) =>
  isMarketplaceFee
    ? [
        { label: "Per Item Fee", value: obj?.per_item_fee, divider: true },
        { label: "Referral Fee", value: obj?.referral_fee, divider: true },
        { label: "Fulfillment Fee", value: obj?.fulfillment_fee, divider: true },
        { label: "Variable Closing Fee", value: obj?.variable_closing_fee, divider: true },
        { label: "Total", value: obj?.total, isHeading: true },
      ]
    : [
        { label: "Price", value: obj?.price },
        { label: "Shipping", value: obj?.shipping, divider: true },
        { label: "Total Fees", value: obj?.total_fees },
        { label: "Cost", value: obj?.cost, divider: true },
        { label: "Profit", value: obj?.profit, isHeading: true },
        { label: "Profit Margin", value: obj?.profit_margin, isPercentage: true },
        {
          label: "Return On Investment",
          value: obj?.return_on_investment,
          isPercentage: true,
        },
      ];

const competitionData = {
  amazon: { label: "Seller ID: ", key: "competitor_seller_id" },
  walmart: { label: "Name: ", key: "competitor_name" },
};

const RepricerListings = () => {
  const dispatch = useDispatch();
  const sortCount = useRef(0);
  const getStrategyLabel = (value, label) => ({ value, label });
  const { account } = useSelector((state) => state.Settings);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const {
    listings,
    listingsResults,
    loading,
    loader,
    listingStatsLoading,
    successMsg,
    error,
    tags,
    strategies,
    listingsStats,
    repricerListingsModalIsOpen,
    assignStrategyModalIsOpen,
  } = useSelector((state) => state.Repricer);
  const mappedTags = $.keyBy(tags?.results, "id");
  const mappedStrategies = $.keyBy(strategies, "id");
  const queryParams = queryString.parse(document.location.search);
  const history = useHistory();
  const [storeFront, setStoreFront] = useState({ label: "", value: "" });
  const [filters, setFilters] = useState({
    page: Number(queryParams.page) || 1,
    per_page: Number(queryParams.per_page) || 50,
  });
  const [selected, setSelected] = useState("");
  const [dimensionModalOpen, setDimensionModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const [selectedMapPrice, setSelectedMapPrice] = useState("");
  const [selectedListedPrice, setSelectedListedPrice] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchType, setSearchType] = useState({ label: "All", value: "" });
  const [selectedMinPrice, setSelectedMinPrice] = useState("");
  const [selectedMaxPrice, setSelectedMaxPrice] = useState("");
  const [minMaxPrice, setMinMaxPrice] = useState({ min_price: "0", max_price: "0" });
  const [minPricePopoverIsOpen, setMinPricePopoverIsOpen] = useState({});
  const [maxPricePopoverIsOpen, setMaxPricePopoverIsOpen] = useState({});
  const [selectColumnsDropDown, setSelectColumnsDropDown] = useState(false);
  const [strategydropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [checkBox, setCheckBox] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const selectedListingsCount = $.size($.keys(checkBox));
  const filteredListingsCount = selectAll ? listings?.count : selectedListingsCount;
  const checkBoxState = selectAll || $.isEqual(selectedListingsCount, $.size(listings?.results));
  const isAnyListingSelected = selectAll || selectedListingsCount;
  const [search, setSearch] = useState("");
  const [loaderKey, setLoaderKey] = useState({});
  const [showProfitMargin, setShowProfitMargin] = useState(true);
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const [repricerListingsModalProps, setRepricerListingsModalProps] = useState({});
  const [assignStrategyModalProps, setAssignStrategyModalProps] = useState({});

  const isWalmart = storeFront?.marketplace === MARKET_PLACES.walmart;
  const LISTING_SEARCH_TYPES = {
    All: "",
    SKU: "sku",
    "Item Id": "marketplace_item_id",
    Asin: "asin",
  };
  const tooltips = {
    min_price: ({ minimum_price: minPrice } = {}) => tooltipLabels(minPrice),
    max_price: ({ maximum_price: maxPrice } = {}) => tooltipLabels(maxPrice),
    marketplace_fees: ({ marketplace_fees: marketplaceFees } = {}) => tooltipLabels(marketplaceFees, true),
    profit: ({ profit } = {}) => tooltipLabels(profit),
  };

  const getLocalStorageItems = (key) => localStorage.getItem(key);
  const setLocalStorageItems = (key, value) => localStorage.setItem(key, value);
  const toggleDimensionModal = () => setDimensionModalOpen(!dimensionModalOpen);
  const handleEditDimension = (item) => {
    setItem(item);
    toggleDimensionModal();
  };

  const SimpleTooltip = ({ data, title, targetKey, value, successText = false, isMinMax = false }) => {
    const RenderValue = () => {
      const formattedValue = $.isNil(value) ? "N/A" : amountText(value);
      return <span className={successText ? "text-success" : ""}>{formattedValue}</span>;
    };

    if (isMinMax && value === "N/A") return null;
    if (value === "N/A") return <RenderValue />;

    const tooltipContent = (
      <TooltipLayout title={title} id={data.id} targetKey={targetKey}>
        <TooltipData data={tooltips[targetKey](data)} />
      </TooltipLayout>
    );

    const calculateIcon = <Calculate id={`${targetKey}-tooltip-${data.id}`} />;

    return (
      <div className="d-flex align-items-center">
        <RenderIf isTrue={!$.isNil(value)}>
          {tooltipContent}
          {calculateIcon}
        </RenderIf>
        {!isMinMax && <RenderValue />}
      </div>
    );
  };

  const SimpleLockTooltip = ({ id, data, targetKey, onClick }) => (
    <>
      <CustomTooltip
        placement="top"
        style={{ maxWidth: "fit-content" }}
        content={
          <div className="mb-2" style={{ width: "312px" }}>
            {data}
          </div>
        }
        target={`${targetKey}-tooltip-${id}`}
        modifiers={{ preventOverflow: { enabled: false }, fade: { enabled: false } }}
      />
      <Lock id={`${targetKey}-tooltip-${id}`} onClick={onClick} />
    </>
  );

  const Spinning = ({ className = "mx-3", width = "20px", height = "20px", color = "primary" }) => (
    <Spinner className={className} color={color} style={{ width, height }} />
  );

  const PriceInput = ({ label, value, onChange, onBlur }) => (
    <div className="mb-2">
      <Label>{label}</Label>
      <Input
        style={{ width: "100%" }}
        type="number"
        value={value || 0}
        step="0.01"
        min="0.01"
        className="form-control"
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (["-", "e", "E"].includes(e.key)) e.preventDefault();
        }}
      />
    </div>
  );

  const MinMaxContent = ({
    data,
    title,
    type,
    selectedPrice,
    setSelectedPrice,
    storeFront,
    loader,
    loaderKey,
    pricePopoverIsOpen,
    setPricePopoverIsOpen,
    minMaxPrice,
  }) => {
    const prefix = type.replace("_price", "");
    return (
      <div className="d-flex align-items-center">
        <SimpleTooltip data={data} title={title} targetKey={type} field={$.startCase(type)} value={getValue(data, `${prefix}imum_price.price`)} isMinMax={true} />
        <RenderIf
          isTrue={$.isNil(data?.minimum_price?.price) || $.isNil(data?.maximum_price?.price)}
          fallback={
            <EditableField
              type={type}
              identifier={data.id}
              value={toF(data?.[`${prefix}imum_price`]?.price)}
              isLoading={loader}
              selected={selectedPrice}
              setSelected={setSelectedPrice}
              loaderKey={loaderKey}
              setLoaderKey={setLoaderKey}
              min={0.01}
              onClick={(value) => {
                dispatch(actions.setRepricerListingsModal(true));
                setRepricerListingsModalProps({
                  onSave: () => {
                    if (
                      (type === "max_price" &&
                        data.maximum_price?.price !== parseFloat(value) &&
                        +data.minimum_price?.price <= +value) ||
                      (type !== "max_price" &&
                        data.minimum_price?.price !== parseFloat(value) &&
                        +value <= +data.maximum_price?.price)
                    ) {
                      updateProduct(type, data.id, parseFloat(value) || 0, storeFront);
                    }
                  },
                  message: `Saving this value will override your ${prefix} value so that it will no longer get updated from your strategy based on cost changes.`,
                  isError:
                    type === "max_price" ? +data.minimum_price?.price > +value : +value > +data.maximum_price?.price,
                  errorMessage:
                    type === "max_price"
                      ? "Max Price should be greater or equal to Min Price"
                      : "Min Price should be less or equal to Max Price",
                });
              }}
            />
          }
        >
          <>
            <span>
              <span>{amountText(data?.[`${prefix}imum_price`]?.price)}</span>
              <i id={`${prefix}_form_${data.id}`} className="ml-2 bx bx-pencil text-primary cursor-pointer" />
            </span>
            <MinMaxForm
              prefix={prefix}
              data={data}
              storeFront={storeFront}
              isPopoverOpen={pricePopoverIsOpen || false}
              setIsPopoverOpen={setPricePopoverIsOpen}
              target={`${prefix}_form_${data.id}`}
              minMaxPrice={minMaxPrice}
              loader={loader}
            />
          </>
        </RenderIf>
        <RenderIf isTrue={data?.locked_values?.includes?.($.toUpper(type))}>
          <RenderIf
            isTrue={loader && loaderKey.type === type && loaderKey.id === data.id && loaderKey.lock}
            fallback={
              <SimpleLockTooltip
                id={data.id}
                data="The Min/Max values are manually overridden. Click here to revert to strategy-based values"
                targetKey={`${prefix}-lock`}
                onClick={() => {
                  setLoaderKey({ type, id: data.id, lock: "lock" });
                  dispatch(actions.setRepricerListingsModal(true));
                  setRepricerListingsModalProps({
                    onSave: () => updateProduct("locked", data.id, type, storeFront),
                    message:
                      "This will remove the manually added override so that this value will get updated based on your formula and strategy",
                  });
                }}
              />
            }
          >
            <Spinner className="mx-1" color="primary" style={{ width: "20px", height: "20px" }} />
          </RenderIf>
        </RenderIf>
      </div>
    );
  };

  const MinMaxForm = ({ data, storeFront, isPopoverOpen, setIsPopoverOpen, target, minMaxPrice, loader }) => {
    const { appTheme } = useSelector((state) => state.Layout);
    const [showError, setShowError] = useState(false);
    const priceFields = ["min", "max"];
    const errorCheck = +minMaxPrice.min_price >= +minMaxPrice.max_price;
    const updateCheck = priceFields.every(
      (col) => (toF(data[`${col}imum_price`]?.price) || "0") === toF(+minMaxPrice[`${col}_price`]),
    );
    const buttonDisableCheck = loader || errorCheck || updateCheck;
    return (
      <UncontrolledPopover
        placementPrefix="bs-popover"
        placement="bottom"
        isOpen={isPopoverOpen?.[data.id]}
        toggle={() => {
          setShowError(false);
          setIsPopoverOpen({ [data.id]: !isPopoverOpen?.[data.id] });
          setMinMaxPrice({
            min_price: data.minimum_price?.price || "0",
            max_price: data.maximum_price?.price || "0",
          });
        }}
        hideArrow={true}
        positionFixed={true}
        className={appTheme}
        trigger="legacy"
        target={target}
      >
        <div className="p-3" style={{ width: "280px" }}>
          {priceFields.map((priceKey) => {
            const field = priceKey + "_price";
            return (
              <PriceInput
                key={`price_field_${field}`}
                label={$.startCase(field)}
                value={minMaxPrice?.[field]}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/^0+(?=\d)/, "");
                  inputValue = inputValue === "" ? "0" : inputValue;
                  setMinMaxPrice({ ...minMaxPrice, [field]: inputValue });
                }}
                onBlur={() =>
                  minMaxPrice?.[field] !== (data[`${priceKey}imum_price?.price`] || "0") && setShowError(true)
                }
              />
            );
          })}
          <RenderIf isTrue={showError}>
            <RenderIf isTrue={updateCheck}>
              <div className="d-flex align-items-center text-warning">
                <i className="bx bx-info-circle mr-1" />
                Please update a field to enable Apply.
              </div>
            </RenderIf>
            <RenderIf isTrue={errorCheck}>
              <div className="d-flex align-items-center text-danger">
                <i className="bx bx-error mr-1" />
                Min Price should be less than Max Price.
              </div>
            </RenderIf>
          </RenderIf>
          <hr />
          <div className="d-flex justify-content-end">
            <PillBtn
              style={{ color: "#9299B8" }}
              className="mr-2"
              title="Cancel"
              name="Cancel"
              onClick={() => {
                setIsPopoverOpen({ [data.id]: false });
                setMinMaxPrice({ min_price: "0", max_price: "0" });
              }}
            />
            <PillBtn
              className={`${buttonDisableCheck ? "cursor-not-allowed" : ""}`}
              disabled={buttonDisableCheck}
              title="Apply"
              name={
                <span>
                  Apply
                  <RenderIf isTrue={loader}>
                    <Spinning className="mx-1" color="white" width="13px" height="13px" />
                  </RenderIf>
                </span>
              }
              color="primary"
              onClick={() => {
                if (updateCheck) return;

                updateProduct(
                  "min_max",
                  data.id,
                  { min_price: +minMaxPrice.min_price, max_price: +minMaxPrice.max_price },
                  storeFront,
                );
                setIsPopoverOpen({ [data.id]: !isPopoverOpen?.[data.id] });
              }}
            />
          </div>
        </div>
      </UncontrolledPopover>
    );
  };

  const optionData = (acc) => ({
    label: acc.name || "",
    value: acc.id ? acc.id : acc.value,
    marketplace: acc.marketplace,
    identifier: acc.marketplace === MARKET_PLACES.walmart ? acc.unique_identifier : acc.access_id,
    valid: acc.valid,
    is_archive: acc.is_archive,
  });

  const updateProduct = (productKey, productId, productValue, storeFront) =>
    dispatch(
      actions.updateListing(
        $.merge({ key: productKey, product_id: productId, value: productValue }, getAccount(storeFront)),
      ),
    );

  const updateProductMapPrice = (productSku, storeFront, productValue, productId) =>
    dispatch(
      actions.updateMapPriceListing(
        $.merge(
          { sku: productSku, account_id: storeFront.identifier, map_price: productValue, productId },
          getAccount(storeFront),
        ),
      ),
    );

  const getBuyBoxPrice = (item) => ({
    price: item.has_buybox ? item.listed_price : item.buybox_price,
    shipping: item.has_buybox ? item.shipping_fee : item.buybox_shipping_fee,
  });

  const stores = getValidStores(accounts, { forRepricer: true });

  const CopyIcon = ({ data, isItemId }) => (
    <span className="text-primary">
      <CopyToClipBoard text={data?.sku || data} padding={1} className={isItemId ? "" : "ml-1"} />
    </span>
  );

  const getValue = (data, key) => {
    const value = $.get(data, key);
    if (value === "N/A" || $.isNil(value)) return;

    if (value === 0) return "0";
    return value;
  };

  const tableHeaders = [
    {
      show: true,
      title: "Item Details",
      Content: ({ data, storeFront }) => {
        const isWalmart = storeFront?.marketplace === "walmart";
        const itemIdentifier = isWalmart ? data.marketplace_item_id : data.asin;
        return (
          <>
            <div style={{ whiteSpace: "nowrap", width: "310px", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span>Title:&nbsp;</span>
              <span style={{ cursor: "default" }} className="inventory-color" title={data.listing_title}>
                {data.listing_title}
              </span>
            </div>
            <div>
              SKU:{" "}
              <span className="inventory-color">
                <RenderIf isTrue={isAdmin()} fallback={<>{data.sku}</>}>
                  <Link
                    className="text-primary"
                    to={`/listing_details/${storeFront?.marketplace}/${data.id}/${data.sku}/${storeFront.identifier}`}
                  >
                    {data.sku}
                  </Link>
                </RenderIf>
                <span className="text-primary">
                  <CopyToClipBoard text={data?.sku || ""} padding={1} className="ml-3" />
                </span>
              </span>
            </div>
            <div className="d-flex">
              {isWalmart ? "Item ID" : "ASIN"}:{" "}
              <a
                className="ml-2 text-primary"
                href={getProductURL({ item_id: itemIdentifier }, storeFront?.marketplace)}
                target="_blank"
                rel="noreferrer"
              >
                {itemIdentifier}
              </a>
              {CopyIcon({ data: itemIdentifier, isItemId: true })}
              <StatusBadge
                style={{ minWidth: "fit-content", marginLeft: "5px" }}
                status={data.fulfillment_type}
                colorsMapping={{ FBA: "success", FBM: "orange", "Seller Fulfilled": "warning" }}
                textParser={$.toUpper}
              />
            </div>
            <div className="d-flex align-items-center">
              <span>Condition: </span>
              <span className="inventory-color ml-2">New</span>
            </div>
          </>
        );
      },
    },
    {
      show: true,
      title: "Status",
      Content: ({ data, storeFront }) => {
        const marketplaceKey = storeFront?.marketplace === "walmart" ? data.publish_status : data.status;
        const statusKey = STATUS[marketplaceKey] ?? marketplaceKey;
        const status = $.capitalize(statusKey);
        return (
          <div className="d-flex align-items-center">
            <StatusBadge
              status={status}
              colorsMapping={{ [`${status}`]: "orange", Published: "success", Unpublished: "danger" }}
              otherComponent={
                <RenderIf
                  isTrue={statusKey === "UNPUBLISHED" && data?.status_change_reason}
                  fallback={<span>{toPascalCase(status)}</span>}
                >
                  <span className="cursor-pointer" id={`status-tooltip-${data.id}`}>
                    {toPascalCase(status)}
                    <CustomTooltip
                      placement="top"
                      content={data?.status_change_reason}
                      target={`status-tooltip-${data.id}`}
                    />
                    <i className="mx-1 text-danger cursor-pointer bx bx-info-circle" />
                  </span>
                </RenderIf>
              }
            />
          </div>
        );
      },
    },
    {
      show: true,
      title: "Stock",
      Content: ({ data }) => (
        <div className="d-flex align-items-center">
          <span className="mx-1 font-size-12">{$.isNil(data.stock) ? "N/A" : data.stock}</span>
          <RenderIf isTrue={data.fulfillment_type === "FBA" && !$.isNil($.get(data, "qty_wise_inventory_ages.reserved_quantity"))}>
            <TooltipLayout title="Stock Details" id={data.id} targetKey="stock">
              <TooltipData
                data={[
                  {
                    label: "Available Quantity",
                    value: data.stock,
                    isSimpleNum: true,
                    divider: true,
                  },
                  {
                    label: "Reserved Quantity",
                    value: data.qty_wise_inventory_ages.reserved_quantity,
                    isSimpleNum: true,
                  },
                ]}
              />
            </TooltipLayout>
            <i id={`stock-tooltip-${data.id}`} className="mx-1 cursor-pointer bx bx-xs bx-info-circle text-primary" />
          </RenderIf>
        </div>
      ),
    },
    {
      show: true,
      title: "Repricing",
      isHide: !isUserHaveFullAccess("repricer"),
      Content: ({ data, storeFront, loader, loaderKey }) => (
        <RenderIf
          isTrue={loader && loaderKey.type === "enabled" && loaderKey.id === data.id}
          fallback={
            <CheckBox
              className="text-center"
              name="repricing"
              state={data.enabled}
              setState={() => updateProduct("enabled", data.id, !data.enabled, storeFront)}
              isSwitch={true}
              onClick={() => setLoaderKey({ type: "enabled", id: data.id })}
            />
          }
        >
          <Spinning />
        </RenderIf>
      ),
    },
    {
      show: true,
      title: "Strategy",
      isHide: !isUserHaveFullAccess("repricer"),
      Content: ({ data, mappedStrategies, strategies, storeFront, index, listings, loader, loaderKey }) => (
        <RenderIf
          isTrue={loader && loaderKey.type === "strategy" && loaderKey.id === data.id}
          fallback={
            <SimpleSelect
              maxMenuHeight={listings?.length <= 2 ? 90 : 200}
              className="mb-0"
              name="strategy"
              menuPlacement={getMenuPlacement(listings, index, false)}
              options={strategies?.reduce(
                (arr, key) => {
                  arr.push(getStrategyLabel(key.id, key.name));
                  return arr;
                },
                [getStrategyLabel(null, "No Strategy")],
              )}
              onChange={(e) => {
                if (e.value !== data.strategy) {
                  updateProduct("strategy", data.id, e.value, storeFront);
                  setLoaderKey({ type: "strategy", id: data.id });
                }
              }}
              value={
                mappedStrategies?.[data.strategy]?.name
                  ? { label: mappedStrategies?.[data.strategy]?.name, value: data.strategy }
                  : { label: "No strategy", value: null }
              }
              formStyle={{ minWidth: "220px" }}
            />
          }
        >
          <span className="d-flex justify-content-center">
            <Spinning />
          </span>
        </RenderIf>
      ),
    },
    {
      show: true,
      title: "Buybox",
      renderHeader: () => (
        <>
          <div>Buybox</div>
          <span>+ Shipping</span>
        </>
      ),
      Content: ({ data }) => {
        const { price, shipping } = getBuyBoxPrice(data);
        return (
          <div className="d-flex align-items-center">
            <i
              className={`bx bx-xs bx-${data?.has_buybox ? "check" : "x"}-circle text-${
                data?.has_buybox ? "success" : "danger"
              }`}
            />
            <div className="mx-1 d-flex flex-direction-column">
              <div>{`${formatNumber(price)}`}</div>
              <div>{`+ ${formatNumber(shipping, "float")}`}</div>
            </div>
          </div>
        );
      },
    },
    {
      show: true,
      title: "Your Price",
      renderHeader: () => (
        <>
          <div>Your Price</div>
          <span>+ Shipping</span>
        </>
      ),
      Content: ({ data, selectedListedPrice, storeFront, loader, loaderKey }) => (
        <div className="d-flex align-items-center">
          <div className="mx-1 d-flex flex-direction-column">
            <div className="d-flex align-items-center">
              <RenderIf isTrue={isUserHaveFullAccess("repricer")} fallback={amountText(data.listed_price)}>
                <EditableField
                  type="listed_price"
                  identifier={data.id}
                  value={$.isNil(data.listed_price) ? "N/A" : toF(data.listed_price)}
                  isLoading={loader}
                  selected={selectedListedPrice}
                  setSelected={setSelectedListedPrice}
                  loaderKey={loaderKey}
                  setLoaderKey={setLoaderKey}
                  onClick={(value) =>
                    parseFloat(value) !== data.listed_price &&
                    updateProduct("listed_price", data.id, parseFloat(value) || 0, storeFront)
                  }
                />
              </RenderIf>
            </div>
            <div>{`+ ${formatNumber(data.shipping_fee, "float")}`}</div>
          </div>
        </div>
      ),
    },
    {
      show: true,
      title: "Tag",
      Content: ({ data: product, storeFront, selectedTag, mappedTags }) => {
        const tag = $.get(mappedTags, product.tag);

        return (
          <RenderIf
            isTrue={selectedTag === product.id}
            fallback={
              <RenderIf
                isTrue={tag}
                fallback={
                  <div
                    className="d-flex align-items-center gap-2 text-primary cursor-pointer"
                    onClick={() => setSelectedTag(product.id)}
                  >
                    <i className="bx bx-plus" /> <span>Add Tag</span>
                  </div>
                }
              >
                <div className="d-flex align-items-center gap-2">
                  <Badge
                    className="d-flex align-items-center justify-content-center gap-2 px-2 font-size-12"
                    color="success"
                    pill
                  >
                    <span>{$.startCase($.get(tag, "name"))}</span>
                    <i
                      className="bx bx-x text-danger cursor-pointer"
                      onClick={() => updateProduct("tag", product.id, null, storeFront)}
                    />
                  </Badge>
                  <i className="bx bx-pencil text-primary cursor-pointer" onClick={() => setSelectedTag(product.id)} />
                </div>
              </RenderIf>
            }
          >
            <EditableFieldWithDropdown
              tag={tag}
              mappedTags={mappedTags}
              clearSelectedTag={() => setSelectedTag(null)}
              createTag={(name) =>
                dispatch(
                  actions.createAndUpdateTag({
                    ...getAccount(storeFront),
                    name,
                    key: "tag",
                    productId: product.id,
                  }),
                )
              }
              updateTag={(tagId) => updateProduct("tag", product.id, tagId, storeFront)}
            />
          </RenderIf>
        );
      },
    },
    {
      show: true,
      title: "Competition",
      Content: ({ data, storeFront }) => {
        const platformOpts = competitionData[storeFront?.marketplace];
        return (
          <>
            <div>
              {platformOpts?.label}
              <RenderIf
                isTrue={storeFront?.marketplace === MARKET_PLACES.amazon && data?.[platformOpts?.key]}
                fallback={<span className="text-primary">{data?.[platformOpts?.key] ?? "N/A"}</span>}
              >
                <Link
                  to={{ pathname: `https://www.amazon.com/sp?ie=UTF8&seller=${data?.[platformOpts?.key]}` }}
                  target="_blank"
                >
                  {data?.[platformOpts?.key]}
                </Link>
              </RenderIf>
            </div>
            <RenderIf isTrue={storeFront?.marketplace === MARKET_PLACES.amazon}>
              <div>
                Total Sellers: <span>{data?.no_of_competitors ?? "N/A"}</span>
              </div>
            </RenderIf>
          </>
        );
      },
    },
    {
      show: true,
      title: "Dimension",
      Content: ({ data }) => (
        <div className="d-flex align-items-center">
          <div>
            <RenderIf isTrue={!$.isEmpty(data?.dimension)} fallback={"N/A"}>
              {Object.entries(data?.dimension || {}).map(([prefix, count], _index) => (
                <div className="d-flex justify-content-left align-items-center" key={"_ao_item_" + _index}>
                  <span style={{ minWidth: "42px" }}>{$.capitalize(prefix)}</span>
                  <i style={{ width: "25px" }} className="bx bx-sm bx-right-arrow-alt text-primary"></i>
                  <span>{count?.toFixed(2) || "N/A"}</span>
                </div>
              ))}
            </RenderIf>
          </div>
          <div>
            <i className="ml-2 bx bx-pencil text-primary cursor-pointer" onClick={() => handleEditDimension(data)} />
          </div>
        </div>
      ),
    },
    {
      show: true,
      isHide: !isProfitCalculationAllowed(),
      title: "COGS",
      Content: ({ data, selected, storeFront, loader, loaderKey }) => (
        <div className="d-flex align-items-center">
          <EditableField
            type="cogs"
            identifier={data.id}
            value={$.isNil(data.cost) ? "N/A" : toF(data.cost)}
            isLoading={loader}
            selected={selected}
            setSelected={setSelected}
            loaderKey={loaderKey}
            setLoaderKey={setLoaderKey}
            onClick={(value) =>
              data.cost !== parseFloat(value) && updateProduct("cost", data.id, parseFloat(value) || 0, storeFront)
            }
          />
        </div>
      ),
    },
    {
      show: true,
      isHide: !isUserHaveFullAccess("repricer"),
      title: "MAP Price",
      Content: ({ data, selectedMapPrice, storeFront, loader, loaderKey }) => (
        <div className="d-flex align-items-center">
          <EditableField
            type="Map Price"
            identifier={data.id}
            value={$.isNil(data?.map_price) ? "N/A" : toF(data?.map_price)}
            isLoading={loader}
            selected={selectedMapPrice}
            setSelected={setSelectedMapPrice}
            loaderKey={loaderKey}
            setLoaderKey={setLoaderKey}
            onClick={(value) =>
              data?.map_price !== parseFloat(value) &&
              updateProductMapPrice(data.sku, storeFront, parseFloat(value) || 0, data.id)
            }
          />
        </div>
      ),
    },
    ...$.map(["min", "max"], (col) => {
      const type = $.startCase(col);
      return {
        show: true,
        title: `${type} Price`,
        isHide: !isUserHaveFullAccess("repricer"),
        Content: (props) => (
          <MinMaxContent
            {...$.pick(props, "data", "storeFront", "loader", "loaderKey", "minMaxPrice")}
            title={`${type} Price Calculation`}
            type={`${col}_price`}
            selectedPrice={props[`selected${type}Price`]}
            setSelectedPrice={props[`setSelected${type}Price`]}
            pricePopoverIsOpen={props[`${col}PricePopoverIsOpen`]}
            setPricePopoverIsOpen={props[`set${type}PricePopoverIsOpen`]}
          />
        ),
      };
    }),
    {
      show: true,
      title: "Fees",
      Content: ({ data }) => (
        <SimpleTooltip
          data={data}
          title="Fee Details"
          targetKey="marketplace_fees"
          field="Fees"
          value={getValue(data, "marketplace_fees.total")}
        />
      ),
    },
    {
      show: true,
      title: "Profit",
      isHide: !isUserHaveFullAccess("repricer") || !isUserHaveFullAccess("extension"),
      Content: ({ data }) => (
        <SimpleTooltip
          data={data}
          title="Profit Details"
          targetKey="profit"
          field="Profit"
          value={getValue(data, "profit.profit")}
          successText={true}
        />
      ),
    },
    {
      show: true,
      title: "Margin/ROI",
      renderHeader: ({ showProfitMargin }) => <>{showProfitMargin ? "Margin" : "ROI"}</>,
      Content: ({ data, showProfitMargin }) => {
        const profitValue = showProfitMargin ? "profit_margin" : "return_on_investment";
        return (
          <span className="d-flex justify-content-center">
            {getValue(data, `profit.${profitValue}`) && data.cost ? (
              toF(getValue(data, `profit.${profitValue}`)) + "%"
            ) : (
              <i className="bx bx-xs text-center bx-infinite" />
            )}
          </span>
        );
      },
    },
    {
      show: true,
      title: "Inventory Age",
      Content: ({ data }) => {
        const getValue = (path) => {
          const value = $.get(data, `qty_wise_inventory_ages.${path}`);
          return $.isNil(value) ? "N/A" : value;
        };

        if ($.get(data, "fulfillment_type") === "FBM") return "N/A";
        return (
          <div className="d-flex flex-column gap-2">
            {$.map(isWalmart ? WALMART_INVENTORY_AGE_FILTERS : INVENTORY_AGE_FILTERS, ({ title, textColor }, key) => (
              <span key={`__${title}__${key}__`}>
                {title}
                {": "}
                <span className={textColor}>{getValue(key)}</span>
              </span>
            ))}
          </div>
        );
      },
    },
    {
      isHide: true,
      title: "Lag Time",
      Content: ({ data }) => (
        <div className="d-flex align-items-center">
          <span>{`${"3 Days"}`}</span>
        </div>
      ),
    },
    {
      isHide: true,
      title: "Unit Sold",
      Content: ({ data }) => <span>{`${"10"}`}</span>,
    },
    {
      isHide: true,
      title: "Weight (lbs)",
      Content: ({ data }) => <span className="d-flex justify-content-center">{`${"10"}`}</span>,
    },
    {
      isHide: true,
      title: "Dimensions",
      Content: ({ data }) => <span className="d-flex justify-content-center">{`${"N/A"}`}</span>,
    },
    {
      isHide: true,
      title: "Est. Shipping Fee",
      Content: ({ data }) => (
        <div className="d-flex justify-content-center align-items-center">
          <span>{`${"N/A"}`}</span>
        </div>
      ),
    },
    {
      show: true,
      title: "Buybox Changed At",
      Content: ({ data }) => (
        <>
          <RenderIf isTrue={data.buybox_changed_timestamp} fallback={<span className="d-flex">N/A</span>}>
            <span>{`${moment(data.buybox_changed_timestamp).format(DATE_FORMATS.FULL_DATE)}`}</span>
          </RenderIf>
        </>
      ),
    },
    {
      isHide: !isUserHaveFullAccess("repricer"),
      title: "Last Repriced At",
      Content: ({ data }) => (
        <>
          <RenderIf isTrue={data.last_repriced_at} fallback={<span className="d-flex">N/A</span>}>
            <span>{`${moment(data.last_repriced_at).format(DATE_FORMATS.FULL_DATE)}`}</span>
          </RenderIf>
        </>
      ),
    },
    {
      isHide: true,
      title: "Action",
      Content: ({ data }) => (
        <div onClick={() => {}}>
          <span onClick={(_) => {}} className="icon-topRight bx-sm cursor-pointer">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
        </div>
      ),
    },
  ];

  const [cols, setCols] = useState($.keyBy(tableHeaders, "title"));

  useEffect(() => {
    setCols($.keyBy(tableHeaders, "title"));
  }, [storeFront]);

  const fieldsMapping = {
    Stock: "stock",
    COGS: "cost",
    "Min Price": "minimum_price",
    "Max Price": "maximum_price",
  };

  const toggleMapping = {
    "Margin/ROI": "",
  };

  const getSortFilter = (title, dir) =>
    setFilters({
      ...filters,
      sort_by: fieldsMapping[title],
      sort_dir: !dir || dir === "desc" ? "asc" : "desc",
      ordering: !dir || dir === "desc" ? fieldsMapping[title] : "-" + fieldsMapping[title],
    });

  const SortArrows = ({ title }) => {
    const sortOpts = [
      ["asc", "up"],
      ["desc", "down"],
    ];
    const isSameCol = fieldsMapping[title] === filters.sort_by;
    return (
      <span
        onClick={() => {
          if (fieldsMapping[title] === filters.sort_by) sortCount.current++;
          else sortCount.current = 1;
          if (sortCount.current === 3) setFilters({ ...filters, sort_by: null, sort_dir: null, ordering: null });
          else getSortFilter(title, isSameCol && sortCount.current < 3 ? filters.sort_dir : null);
        }}
      >
        {sortOpts
          .filter(([dir]) => (isSameCol ? dir === filters.sort_dir : true))
          .map(([, arrow], index) => (
            <i
              key={"_sort_opts_" + index}
              id={arrow + "-filter"}
              style={{ fontSize: "10px" }}
              className={`dripicons-arrow-thin-${arrow} cursor-pointer`}
            ></i>
          ))}
      </span>
    );
  };

  const Calculate = ({ id }) => <i id={id} className={"mx-1 cursor-pointer bx bx-xs bx-calculator text-primary"} />;

  const Lock = ({ id, onClick = null }) => (
    <i id={id} className={"mx-1 cursor-pointer bx bx-xs bx-lock text-warning"} onClick={onClick} />
  );

  const isVisible = (x, isShow = true) => {
    if (x.isHide) return false;
    if (!x) return false;
    if (!x.show && isShow) return false;
    return true;
  };

  useEffect(() => {
    const marketplace = isJSON(getLocalStorageItems("listingsSelectedMarketplace"));
    if (stores?.length) {
      const { account_id: accountId } = queryParams;
      const acc = (accountId && $.find(stores, ({ id }) => id === +accountId)) || $.first(stores);
      setStoreFront(marketplace && !accountId ? marketplace : optionData(acc));
    }
  }, [accounts?.data]);

  const redirectToBasePath = () => {
    if (filters.page !== 1 && filters.page_number !== 1) return;

    const { pathname, search } = history.location;
    if (search === "") return;

    history.push(pathname);
  };

  useEffect(() => {
    if (loading || !storeFront.value) return;

    const limit = filters?.per_page;
    const offset = (filters?.page - 1) * filters?.per_page;

    const fetchListingParams = $.merge(
      getAccount(storeFront),
      $.omit(filters, DATE_FILTERS),
      limit && { limit },
      offset && { offset },
    );

    DATE_FILTERS.forEach((filterKey) => {
      const filterValue = parseInt($.get(filters, filterKey));
      if (filterValue) {
        const dateValue = moment().subtract(filterValue, "minutes").format("YYYY-MM-DDTHH:mm:ss");
        const suffix = filterValue === 9999 ? "__lte" : "__gte";
        $.set(fetchListingParams, filterKey + suffix, dateValue);
      }
    });

    dispatch(actions.fetchListings(fetchListingParams));
    redirectToBasePath();

    const { account_id: accountId } = queryParams;
    if (!accountId) {
      setLocalStorageItems("listingFilters", JSON.stringify(filters));
    }
  }, [filters, storeFront.value]);

  useEffect(() => {
    if (!storeFront.value) return;
    dispatch(actions.getTags(storeFront?.marketplace));
    isUserHaveFullAccess("repricer") && dispatch(actions.getStrategies(getAccount(storeFront)?.account?.marketplace));
    dispatch(actions.fetchListingsStats(getAccount(storeFront)));
  }, [storeFront.value]);

  useEffect(() => {
    if (!loader) setLoaderKey({});
  }, [loader]);

  useEffect(() => {
    const { sku } = queryParams;
    if (!sku) return;

    setSearchType({ label: "SKU", value: "" });
    setSearch(sku);
    setFilters({ ...filters, search: sku.trim(), page: 1, search_on: LISTING_SEARCH_TYPES.SKU });
    setTimeout(() => {
      removeURLQueryParams();
    }, 3000);
  }, [queryParams.sku]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!search.trim()) return;

    setFilters({ ...filters, search: search.trim(), page: 1, search_on: LISTING_SEARCH_TYPES[searchType.label] });
  };

  useEffect(() => {
    dispatch(setPreloader(loader || listingStatsLoading || loading));
  }, [loader, listingStatsLoading, loading]);

  const toggleDrawer = () => setAreFiltersOpen((prevState) => !prevState);

  useEffect(() => {
    const { account_id: accountId } = queryParams;
    if (accountId) return;

    const savedColumns = isJSON(getLocalStorageItems("repricerListingsColumns"));
    const profitMargin = getLocalStorageItems("showProfitMargin");
    const savedSearch = getLocalStorageItems("listingsSearch");
    const savedSearchType = getLocalStorageItems("listingsSearchType");
    const savedFilters = isJSON(getLocalStorageItems("listingFilters"));

    if (savedColumns) {
      tableHeaders.forEach((item) => {
        if (savedColumns[item.title]) item.show = savedColumns[item.title].show;
      });
    }

    if (savedFilters && !Array.isArray(savedFilters)) setFilters(savedFilters);
    savedSearch && setSearch(savedSearch);
    profitMargin && setShowProfitMargin(isJSON(profitMargin));
    savedSearchType && setSearchType(isJSON(savedSearchType));
  }, []);

  const tagFilterDefaultValue = { value: "", label: "All tags" };

  const getTagFilterValue = () => ({
    label: $.startCase(mappedTags?.[filters.tag_id]?.name ?? tagFilterDefaultValue.label),
    value: mappedTags?.[filters.tag_id]?.id ?? tagFilterDefaultValue.value,
  });

  return (
    <>
      <FiltersDrawer isOpen={areFiltersOpen} toggleDrawer={toggleDrawer}>
        <ListingFilters
          toggleDrawer={toggleDrawer}
          globalFilters={filters}
          setGlobalFilters={setFilters}
          initialOptions={{ strategies, tags, isWalmart, showProfitMargin }}
        />
      </FiltersDrawer>
      <RenderIf isTrue={loader || listingStatsLoading}>
        <Preloader />
      </RenderIf>
      <RenderIf isTrue={repricerListingsModalIsOpen}>
        <ConfirmationDialog {...repricerListingsModalProps} />
      </RenderIf>
      <RenderIf isTrue={assignStrategyModalIsOpen}>
        <AssignStrategyConfirmationDialog {...assignStrategyModalProps} />
      </RenderIf>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Listings" />
          <RenderIf isTrue={isUserHaveFullAccess("repricer") && daysLeftInTrial(account?.data) > 0}>
            <HeaderBanner>
              <div className="fs-20 fw-bold font-size-20 text-white listing-banner-para text-center align-items-center">
                Repricer Trial Unlocked! <span className="price">{`${daysLeftInTrial(account?.data)} Days left`}</span>{" "}
                in your trial!
              </div>
            </HeaderBanner>
          </RenderIf>
          <RenderIf isTrue={listingsStats}>
            <RepricerListingStats
              listingsStats={listingsStats}
              isProfitCalculationAllowed={isProfitCalculationAllowed()}
              filters={filters}
              setFilters={setFilters}
              storeFront={storeFront}
            />
          </RenderIf>
          <RenderIf isTrue={isWalmart}>
            <Alert color="warning" className="d-flex align-items-center">
              <i className="bx bx-info-circle pr-2" />
              Quantities may be inaccurate due to an issue in Walmart's reports.
            </Alert>
          </RenderIf>
          <RenderIf isTrue={successMsg}>
            <div className="auto-hide">
              <Alert color="success">
                <i className="bx bx-info-circle pr-2"></i>
                {successMsg}
              </Alert>
            </div>
          </RenderIf>
          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error}
              </Alert>
            </div>
          </RenderIf>
          <Card className="mb-4">
            <CardHeader className="py-2">
              <Row className="d-flex justify-content-between align-items-center mr-1">
                <div className="d-flex">
                  <form className="app-search d-lg-block" onSubmit={onSubmit}>
                    <input type="submit" hidden />
                    <div className="d-flex inputs-group">
                      <Select
                        name="select_type"
                        value={searchType}
                        className="select2-container mb-0 form-group"
                        onChange={(e) => {
                          const { account_id: accountId } = queryParams;
                          const searchType = { label: e.label, value: e.value };
                          setSearchType(searchType);
                          if (!accountId) {
                            setLocalStorageItems("listingsSearchType", JSON.stringify(searchType));
                          }
                          search &&
                            setFilters({
                              ...filters,
                              search: search.trim(),
                              page: 1,
                              search_on: LISTING_SEARCH_TYPES[e.label],
                            });
                        }}
                        options={$.map(
                          $.entries(
                            $.omit(
                              LISTING_SEARCH_TYPES,
                              storeFront.marketplace === MARKET_PLACES.amazon ? ["Item Id"] : ["Asin"],
                            ),
                          ),
                          ([label, value]) => ({ label, value }),
                        )}
                        classNamePrefix="select2-selection"
                      />
                      <div className="vertical-line2" />
                      <div className="position-relative">
                        <i
                          onClick={onSubmit}
                          className="fas fa-arrow-circle-right text-primary fa-lg top-search-arrow-icon cursor-pointer"
                        />
                        <input
                          id="search_field"
                          type="text"
                          className="form-control search-input"
                          placeholder="Search..."
                          value={search}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setFilters({ ...filters, search: "", page: 1 });
                              setSearch("");
                              localStorage.removeItem("listingsSearch");
                            } else {
                              const { account_id: accountId } = queryParams;
                              setSearch(e.target.value);
                              if (!accountId) {
                                setLocalStorageItems("listingsSearch", e.target.value);
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <Dropdown className="d-none" isOpen={false} direction="down" toggle={() => {}}>
                    <DropdownToggle
                      className="toc-heading-cursor ml-3 mb-0"
                      data-toggle="dropdown"
                      aria-expanded={false}
                      tag="span"
                    >
                      <i className="bx bx-sm bx-download text-primary ml-4 cursor-pointer" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem key="_download_skus_" onClick={() => {}}>
                        Download SKUs
                      </DropdownItem>
                      <DropdownItem key="_download_missing_suppliers_" onClick={() => {}}>
                        Download SKUs Missing Costs
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <ButtonDropdown
                    isOpen={selectColumnsDropDown}
                    toggle={() => setSelectColumnsDropDown(!selectColumnsDropDown)}
                    className="mr-1"
                  >
                    <DropdownToggle tag="i" className="bx bx-sm bx-columns text-primary cursor-pointer" />
                    <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }}>
                      {tableHeaders
                        .filter((x) => isVisible(x, false))
                        .map((header, key) => (
                          <DropdownItem key={`_select_columns_+ ${key}`}>
                            <div
                              className="custom-control custom-checkbox"
                              onClick={(e) => {
                                e.stopPropagation();
                                const { account_id: accountId } = queryParams;
                                cols[header.title].show =
                                  $.size($.values(cols).filter((value) => value.show)) > 1
                                    ? !cols[header.title]?.show
                                    : true;
                                setCols({ ...cols });
                                if (!accountId) {
                                  setLocalStorageItems("repricerListingsColumns", JSON.stringify(cols));
                                }
                              }}
                            >
                              <input
                                checked={!!cols[header.title]?.show}
                                type="checkbox"
                                className="custom-control-input"
                                onChange={() => false}
                              />
                              <label className="custom-control-label">{header.title}</label>
                            </div>
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <Button disabled={loading} onClick={toggleDrawer} className="transparent-button">
                    <i
                      className={`bx bx-sm bx-filter-alt text-primary ${
                        loading ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                    />
                  </Button>
                  <RenderIf isTrue={stores.length > 1}>
                    <FormGroup className="mx-1 select2-container mt-3">
                      <StoreSelector
                        value={storeFront}
                        filters={filters}
                        setFilters={setFilters}
                        onChange={setStoreFront}
                        forRepricer={true}
                        optionData={optionData}
                        formStyle={{ marginBottom: "0" }}
                      />
                    </FormGroup>
                  </RenderIf>
                  <SimpleSelect
                    name="tags"
                    value={getTagFilterValue()}
                    onChange={(e) => {
                      setFilters({ ...filters, tag_id: e.value, page: 1, page_number: 1 });
                    }}
                    options={[
                      tagFilterDefaultValue,
                      ...$.map(tags?.results || [], (tag) => ({ label: tag.name, value: tag.id })),
                    ]}
                    formStyle={{ width: "200px", marginTop: "17px" }}
                    classNamePrefix="select2-selection"
                  />
                  <RenderIf isTrue={isAnyListingSelected}>
                    <ButtonDropdown
                      isOpen={strategydropdownIsOpen}
                      direction="down"
                      toggle={() => setDropdownIsOpen(!strategydropdownIsOpen)}
                    >
                      <DropdownToggle color="success" className="rounded">
                        Assign Strategy
                        <i className="mdi mdi-chevron-down ml-1" />
                      </DropdownToggle>
                      <DropdownMenu className="overflow-auto" style={{ maxHeight: "300px" }} position="absolute">
                        {$.map(strategies, (strategy, ind) => (
                          <DropdownItem
                            key={`strategy-${ind}`}
                            onClick={() => {
                              dispatch(actions.setAssignStrategyModal(true));
                              setAssignStrategyModalProps({
                                productKey: "strategy",
                                strategy: strategy?.name,
                                strategyId: strategy?.id,
                                selectAll,
                                selectedlistings: $.keys(checkBox),
                                params: $.merge(
                                  $.omit(filters, ["sort_by", "sort_dir", "ordering", "per_page", "page_number"]),
                                  { platform: storeFront?.marketplace, marketplace_account_id: storeFront?.identifier },
                                  getAccount(storeFront),
                                ),
                                setSelectAll,
                                setCheckBox,
                              });
                            }}
                          >
                            {strategy?.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </RenderIf>
                  <RenderIf isTrue={isProfitCalculationAllowed()}>
                    <Link to="/upload_listings_new" target="_blank">
                      <Button color="primary">Update COGS in Bulk</Button>
                    </Link>
                  </RenderIf>
                  <Link className="d-none" to="/listings/new" target="_blank">
                    <Button color="primary">Add Listing</Button>
                  </Link>
                </div>
              </Row>
            </CardHeader>
          </Card>
          <Card>
            <RenderIf isTrue={isAnyListingSelected}>
              <div className="mb-3 blockquote-warning">
                <h6 className="d-flex align-items-center">
                  <span className="text-warning">
                    {`${filteredListingsCount} listing${filteredListingsCount > 1 ? "s" : ""}`}&nbsp;
                  </span>{" "}
                  selected on current page
                </h6>
                <h6 className="d-flex align-items-center">
                  <CheckBox
                    state={selectAll}
                    setState={(e) => {
                      setCheckBox({});
                      setSelectAll(!selectAll);
                    }}
                  />
                  <span>
                    Select All <span className="text-warning">{listings?.count} filtered listings</span> (Only few
                    actions will work)
                  </span>
                </h6>
              </div>
            </RenderIf>
            <FilterChipsBar
              filters={filters}
              setFilters={setFilters}
              initialOptions={{ strategies, tags, isWalmart, showProfitMargin }}
            />
            <ReactTable
              className="table-select"
              tableHeader={
                <>
                  <th>
                    <CheckBox
                      state={isAnyListingSelected && checkBoxState}
                      setState={(e) => {
                        setSelectAll(false);
                        checkBoxState
                          ? setCheckBox({})
                          : setCheckBox(
                            $.reduce(listings?.results, (acc, cur) => $.merge(acc, { [cur.id]: true }), {}),
                          );
                      }}
                    />
                  </th>
                  {Object.values(cols)
                    .filter((x) => isVisible(x, true))
                    .map((header, index) => (
                      <th key={`table-header ${index}`}>
                        {header.renderHeader ? (
                          <header.renderHeader showProfitMargin={showProfitMargin} />
                        ) : (
                          header.title
                        )}
                        <RenderIf isTrue={$.keys(fieldsMapping).includes(header.title)}>
                          <div className="d-inline ml-1">
                            <SortArrows title={header.title} />
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={$.keys(toggleMapping).includes(header.title)}>
                          <div className="d-inline ml-1">
                            <i
                              className="text-primary cursor-pointer bx bx-xs bx-transfer-alt"
                              onClick={() => {
                                const { account_id: accountId } = queryParams;
                                setShowProfitMargin(!showProfitMargin);
                                if (!accountId) {
                                  setLocalStorageItems("showProfitMargin", !showProfitMargin);
                                }
                              }}
                            ></i>
                          </div>
                        </RenderIf>
                      </th>
                    ))}
                </>
              }
              style={{ width: "100%", overflow: "auto" }}
              tableBody={
                <RenderIf
                  isTrue={!$.isEmpty(listingsResults)}
                  fallback={
                    <RenderIf isTrue={!loading}>
                      <tr>
                        <td colSpan="100%">
                          <h2 className="text-center">No Records Found</h2>
                        </td>
                      </tr>
                    </RenderIf>
                  }
                >
                  {$.map(listings?.results, (data, key) => (
                    <tr key={`table-row ${key}`}>
                      <th>
                        <CheckBox
                          state={selectAll || checkBox[data.id] || false}
                          setState={(e) => {
                            setSelectAll(false);
                            checkBox[data.id] ? delete checkBox[data.id] : (checkBox[data.id] = true);
                            setCheckBox({ ...checkBox });
                          }}
                        />
                      </th>
                      {$.values(cols)
                        .filter((x) => isVisible(x, true))
                        .map((header, colKey) => (
                          <td key={`table-col ${colKey}`}>
                            <header.Content
                              listings={listings?.results}
                              data={data}
                              showProfitMargin={showProfitMargin}
                              selected={selected}
                              selectedMapPrice={selectedMapPrice}
                              selectedListedPrice={selectedListedPrice}
                              selectedTag={selectedTag}
                              selectedMinPrice={selectedMinPrice}
                              setSelectedMinPrice={setSelectedMinPrice}
                              selectedMaxPrice={selectedMaxPrice}
                              setSelectedMaxPrice={setSelectedMaxPrice}
                              minMaxPrice={minMaxPrice}
                              mappedTags={mappedTags}
                              mappedStrategies={mappedStrategies}
                              loader={loader}
                              strategies={strategies}
                              storeFront={storeFront}
                              loaderKey={loaderKey}
                              minPricePopoverIsOpen={minPricePopoverIsOpen}
                              setMinPricePopoverIsOpen={setMinPricePopoverIsOpen}
                              maxPricePopoverIsOpen={maxPricePopoverIsOpen}
                              setMaxPricePopoverIsOpen={setMaxPricePopoverIsOpen}
                              index={key}
                            />
                          </td>
                        ))}
                    </tr>
                  ))}
                </RenderIf>
              }
            />
            <RenderIf isTrue={!$.isEmpty(listings?.results)}>
              <CustomPagination
                total={listings?.count}
                page={filters.page}
                perPage={filters.per_page}
                tabsFilter={filters}
                setTabFilter={setFilters}
                pageOptions={[50, 100, 250]}
                repricer={true}
              />
            </RenderIf>
          </Card>
        </Container>
      </div>
      <DimensionModal
        isOpen={dimensionModalOpen}
        toggleDimensionModal={toggleDimensionModal}
        data={item}
        updateProduct={updateProduct}
        storeFront={storeFront}
      />
    </>
  );
};

export default RepricerListings;
