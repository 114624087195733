import React, { useEffect, useMemo } from "react";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import Chart from "react-apexcharts";
import getBrandImageSrc from "../../../../utils/brandImages";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/repricer/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { map, flatMap, min, max, orderBy } from "lodash";
import { RenderIf, amountText } from "../../../../utils/common";

const BrandLogo = ({ brand }) => {
  return (
    <div className="d-inline-flex align-items-center" style={{ marginLeft: "auto" }}>
      <img src={getBrandImageSrc(brand)} alt={`#${brand} logo`} />
      <span className="text-capitalize">{brand}</span>
    </div>
  );
};

export default function PriceChangeHistory ({ dateRange }) {
  const dispatch = useDispatch();
  const { priceChangeHistories, loading } = useSelector((state) => state.Repricer);
  const { appTheme } = useSelector((state) => state.Layout);
  const { platform, itemId, mpAccountId, sku } = useParams();

  useEffect(() => {
    dispatch(
      actions.fetchRepricerPriceChangeHistories({
        platform: platform === "amazon" || platform === "walmart" ? platform : null,
        search: sku,
        account: mpAccountId,
        start_date: dateRange?.[0],
        end_date: dateRange?.[1],
        priceChangeHistory: true,
      }),
    );
  }, [platform, itemId, dateRange]);

  const results = useMemo(() => {
    return orderBy(priceChangeHistories?.results, (item) => new Date(item.time), ["asc"]);
  }, [priceChangeHistories?.results]);

  const [minPrice, maxPrice, series] = useMemo(
    () => {
      const flatMappedResults = flatMap(results, (item) => [item.minimum_price, item.new_price, item.maximum_price]);
      const minPrice = min(flatMappedResults);
      const maxPrice = max(flatMappedResults);
      const series = [
        { name: "Price", data: map(results, (item) => item.new_price) },
        { name: "Min Price", data: map(results, (item) => item.minimum_price) },
        { name: "Max Price", data: map(results, (item) => item.maximum_price) },
      ];

      return [minPrice, maxPrice, series];
    },
    [results],
  );

  function createTooltipRow ({ label, value, color, isAmount = true }) {
    const isPriceChangeReason = label === "Price Change Reason";
    const textColor = isPriceChangeReason
      ? (appTheme === "light" ? "#000000" : "#a6b0cf")
      : color;
    return `
      <p style="margin: 0; font-size: 14px; color: ${textColor}; word-wrap: break-word; white-space: normal;">
        <strong>${label}:</strong> ${isAmount ? `${value}` : value}
      </p>
    `;
  }

  const data = {
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const point = results[dataPointIndex];
          return `
            <div style="background-color: ${appTheme === "light" ? "#ffffff" : "#222737"}; border-radius: 8px; padding: 18px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); font-family: Arial, sans-serif; width: 350px;">
              <p style="margin: 0 0 12px; font-size: 14px; color:${appTheme === "light" ? " #555" : "#a6b0cf"}; font-weight: bold;">
                ${moment(point.time).format("DD MMM HH:mm:ss.SSS")}
              </p>
              <hr />
              ${[
                { label: "New Price", value: isNaN(parseFloat(point.new_price)) ? "N/A" : parseFloat(point.new_price).toFixed(2), color: "#008FFB" },
                { label: "Old Price", value: isNaN(parseFloat(point.old_price)) ? "N/A" : parseFloat(point.old_price).toFixed(2), color: "#28a745" },
                { label: "Strategy", value: point.strategy_name, color: "#ffc107", isAmount: false },
                { label: "Cost", value: isNaN(parseFloat(point.cost)) ? "N/A" : parseFloat(point.cost).toFixed(2), color: "#e50079" },
                { label: "Max Price", value: isNaN(parseFloat(point.maximum_price)) ? "N/A" : parseFloat(point.maximum_price).toFixed(2), color: "#17a2b8" },
                { label: "Min Price", value: isNaN(parseFloat(point.minimum_price)) ? "N/A" : parseFloat(point.minimum_price).toFixed(2), color: "#6f42c1" },
                { label: "Price Change Reason", value: point.logs?.reason !== undefined ? point.logs.reason : "N/A", color: "#008FFB" },
              ]
                .map(createTooltipRow)
                .join("")}
            </div>
          `;
        },
      },
      xaxis: {
        categories: map(results, (item) => moment(item.time).format("DD MMM HH:mm:ss.SSS")),
        labels: {
          format: "HH:mm:ss.SSS",
          style: {
            fontSize: "8px",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          min: minPrice - 1, // adding buffer to show full line height
          max: maxPrice + 1, // adding buffer to show full line height
          labels: {
            formatter: function (value) {
              return amountText(value);
            },
          },
        },
      ],
      stroke: {
        curve: "straight",
      },
      colors: ["#008FFB", "#6f42c1", "#17a2b8"],
    },
    series,
  };

  return (
    <Card>
      <CardBody className="p-4">
        <div className="d-flex align-items-center mb-3">
          <BrandLogo brand={platform} />
        </div>
        <CardTitle tag="h5">Price history</CardTitle>
        <RenderIf isTrue={!loading} fallback={<center className="my-5"><Spinner color="primary" style={{ width: "36px", height: "36px" }} /></center>}>
          <Chart options={data.options} series={data.series} height={350}/>
        </RenderIf>
      </CardBody>
    </Card>
  );
}
